exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 120) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(
      `${location.hash.replace(/^#(\d)/, "#\\3$1")}`
    ).offsetTop;

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: "smooth",
    });
  }

  return true;
}
